Drupal.behaviors.gnavCart = (function ($, _, site, generic) {
  'use strict';

  // Private variables:
  var $blocks = $();
  var $trigger = $();
  var $counters = $();
  var data = {
    item_count: 0,
    subtotal: '',
    points: 0,
    order_within: '',
    ship_sooner_date: '',
    items: [],
    singular: 0,
    new_items: []
  };
  var state = 'empty';
  var loaded = false;
  var closeNav = false;
  var closeTimeout;

  // Replace dots in the top-level key names the server is giving us.
  // 'prod.PROD_RGN_NAME' --> 'prod_PROD_RGN_NAME'
  function _normalizeResponseKeys(items) {
    var replaceKey = function (key) {
      return key.replace(/\./, '_');
    };
    var normalizeArray = function (array) {
      var newArray = [];

      for (var x = 0, alen = array.length; x < alen; x++) {
        var innerObj = {};

        if (typeof array[x] == 'string') {
          // When the array just has a string, just push that.
          newArray.push(array[x]);
        } else {
          // When the array is anything else, just push that.
          for (var innerKey in array[x]) {
            innerObj[replaceKey(innerKey)] = array[x][innerKey];
          }
          newArray.push(innerObj);
        }
      }

      return newArray;
    };
    var out = [];

    for (var i = 0, len = items.length; i < len; i++) {
      out[i] = {};
      for (var key in items[i]) {
        if (items[i].hasOwnProperty(key)) {
          // Need to go deeper for collection items.
          if ($.isArray(items[i][key])) {
            out[i][replaceKey(key)] = normalizeArray(items[i][key]);
          } else {
            out[i][replaceKey(key)] = items[i][key];
          }
        }
      }
    }

    return out;
  }

  function _setCloseTimeout() {
    if (state !== 'added') {
      return;
    }

    closeTimeout = setTimeout(function () {
      if (closeNav) {
        // Drupal.behaviors.gnav.close();
        // ghetto, but gets the job done
        $('.js-gnav-util-close').trigger('click');
      }
      behavior.setState();
    }, 5000);
  }

  function _clearCloseTimeout() {
    clearTimeout(closeTimeout);
  }

  // Public methods:
  var behavior = {
    attach: function (context) {
      var $cartTrigger = $('.js-gnav-util-trigger--cart', context);
      var label = site.translations.product.stores_header || 'Stores';
      var path = site.translations.product.stores_path || '/about#stores';

      $cartTrigger.each(function () {
        var $cartItem = $(this);

        if ($cartItem.attr('title') === label) {
          $cartItem.find('.js-bag-icon').attr('class', 'hidden');
          $cartItem.find('.js-map-marker-icon').attr('class', 'icon icon--map-marker js-map-marker-icon');
          $cartItem
            .off('click')
            .attr('href', path)
            .removeClass('gnav-util__icon')
            .removeClass('gnav-util__icon--cart')
            .addClass('icon')
            .addClass('js-map-marker mobile-hidden')
            .on('click', function () {
              window.location = path;
            });
          $cartItem.siblings('.js-gnav-util__content').remove();
          $('.js-map-marker', context).closest('.gnav-util--cart').addClass('gnav-util--stores').removeClass('hidden');

          return;
        } else if (!!generic.cookie('stores')) {
          $cartItem.off('click').empty().removeClass('gnav-util__icon').removeClass('gnav-util__icon--cart');
        }
      });

      $trigger = $trigger.add($('.gnav-util--cart', context)).first();
      $blocks = $blocks.add($('.js-gnav-util__content__inner--cart', context));
      $counters = $counters.add($('.gnav-util__icon__cart-count', context));

      // Get the initial item count from the cookie to avoid unnecessary trips
      // to the server.
      this.setData({ item_count: site.userInfoCookie.getValue('item_count') - 0 });

      // fix position of bag counter for IE or Edge
      var isMSbrowser =
        navigator.userAgent.indexOf('MSIE') !== -1 ||
        navigator.userAgent.indexOf('Edge') !== -1 ||
        navigator.appVersion.indexOf('Trident/') !== -1;

      if (isMSbrowser) {
        $counters.attr('y', '82%');
      }
    },

    render: function () {
      var rendered = site.template.get({
        name: 'gnav_cart_content',
        data: data
      });

      // Some of the field values may actually contain mustache themselves, so
      // this template needs to be run through rendering a second time.
      rendered = site.template.render(rendered, data);

      $blocks.html(rendered);

      // Update the counters that are outside of the template
      $counters.text(data.item_count);

      $(document).trigger('siteToggle.currency');

      return this;
    },

    load: function (force) {
      if (loaded && (!_.isBoolean(force) || !force)) {
        return this;
      }

      $blocks.addClass('loading');

      generic.jsonrpc.fetch({
        method: 'trans.get',
        params: [
          {
            trans_fields: ['TRANS_ID', 'totals'],
            payment_fields: [],
            order_fields: ['items', 'samples', 'offerCodes']
          }
        ],
        onSuccess: function (response) {
          $blocks.removeClass('loading');

          var value = response.getValue();
          var cartItems = value.order.items.concat(value.order.samples);

          if (_.isUndefined(value) || !value) {
            return;
          }

          behavior.setData({
            subtotal: value.formattedSubtotal,
            points: value.points,
            order_within: '', // This was removed from the designs
            ship_sooner_date: '', // This was removed from the designs
            item_count: value.items_count,
            items: _normalizeResponseKeys(cartItems)
          });
        },
        onError: function () {
          $blocks.removeClass('loading');
          // @TODO: a failure message should go here.
          loaded = false;
        }
      });

      // Don't put loaded in success function! That allows the user to fire
      // additonal requests while the first is still loading.
      loaded = true;

      return this;
    },

    addItem: function (result) {
      if (_.isUndefined(result) || !result || _.isUndefined(result.trans_data) || _.isUndefined(result.ac_results)) {
        return this;
      }

      var resultType = this.getResultType(result.ac_results);
      var addedItems = '';

      if (resultType != 'sku') {
        addedItems = this.getAddedCollection(result);
      } else {
        addedItems = _.map(result.ac_results, function (value) {
          var res = value.result;
          var item = res.CARTITEM;

          // Seems very dumb to calculate this on the front end.
          item.new_qty = Math.max(1, item.ITEM_QUANTITY - res.PREVIOUS_ITEM_QUANTITY);

          return item;
        });
      }
      var allItems = result.trans_data.order.items;

      this.setData({
        subtotal: result.trans_data.formattedSubtotal,
        points: result.trans_data.points === 0 ? 0 : result.trans_data.points || data.points,
        items: _normalizeResponseKeys(allItems),
        item_count: result.trans_data.items_count,
        new_items: _normalizeResponseKeys(addedItems)
      });

      // Temporarily set the added state:
      this.setState('added');
      $('.menu__primary--active').removeClass('menu__primary--active');
      // @todo we do not have a global function for gnav
      // @setup new brand - this can be re-enabled if gnav elements need to be bound
      // Drupal.behaviors.gnav.open($trigger);

      closeNav = true;
      // The response after you added to bag contains trans_data.order.items,
      // which should be your entire cart, so there's no reason to load the cart
      // again:
      loaded = true;
      _setCloseTimeout();

      return this;
    },

    addOffer: function (result) {
      if (_.isUndefined(result) || !result || _.isUndefined(result.trans) || _.isUndefined(result.items)) {
        return this;
      }
      // var resultType = this.getResultType(result.ac_results);

      // var addedItems = '';
      // addedItems = _.map(result.ac_results, function(value) {
      //   var item = result.items;

      //   // Seems very dumb to calculate this on the front end.
      //   item.new_qty = Math.max(1, item.ITEM_QUANTITY - res.PREVIOUS_ITEM_QUANTITY);

      //   return item;
      // });

      this.setData({
        subtotal: result.trans.formattedSubtotal,
        points: result.trans.points === 0 ? 0 : result.trans.points || data.points,
        items: _normalizeResponseKeys(result.trans.order.items),
        item_count: result.trans.items_count,
        new_items: _normalizeResponseKeys(result.trans.order.samples)
      });

      // Temporarily set the added state:
      this.setState('added');
      $('.menu__primary--active').removeClass('menu__primary--active');
      // @todo we do not have a global function for gnav
      // @setup new brand - this can be re-enabled if gnav elements need to be bound
      // Drupal.behaviors.gnav.open($trigger);

      closeNav = true;
      // The response after you added to bag contains trans.order.items,
      // which should be your entire cart, so there's no reason to load the cart
      // again:
      loaded = true;
      _setCloseTimeout();

      return this;
    },

    // Setters:
    setState: function (newState) {
      var states = ['empty', 'nonempty', 'added'];
      var classPrefix = 'cart-block--';
      var stateClasses = classPrefix + states.join(' ' + classPrefix);

      // If state is undefined, figure it out:
      if (_.isUndefined(newState)) {
        state = data.item_count > 0 ? 'nonempty' : 'empty';
      }
      // Sanity check:
      else if (!_.contains(states, newState)) {
        throw new Error('"' + newState + '" is not a valid cart state.');
      } else {
        state = newState;
      }

      $blocks.removeClass(stateClasses).addClass(classPrefix + state);
      // $trigger.toggleClass('gnav-util--cart--nonempty', state !== 'empty');
      $trigger.toggleClass('gnav-util--cart--empty', state == 'empty');

      return this;
    },

    setData: function (newData) {
      _.extend(data, newData);
      data.singular = data.item_count === 1;

      this.setState().render();

      return this;
    },

    // Getters:

    // Find the collection that's being added to cart (for addItem)
    getAddedCollection: function (results) {
      if (!results || !results.ac_results || !results.trans_data) {
        return null;
      }

      var transData = results.trans_data;
      var orderData = transData.order;
      var collectionIds = _.map(results.ac_results, function (obj) {
        var result = obj.result;

        return result && result.COLLECTION_ID && result.CARTITEM ? result.COLLECTION_ID : null;
      });
      var collectionId = _.compact(collectionIds);
      var collectionResult = _.filter(orderData.items, function (item) {
        return _.contains(collectionId, item.COLLECTION_ID);
      });

      return collectionResult;
    },

    getState: function () {
      return state;
    },

    getData: function (key) {
      return _.isUndefined(key) ? data : data[key];
    },

    getResultType: function (results) {
      var type = 'sku';
      var isCollection = _.filter(results, function (result) {
        return result.instance == 'alter_collection';
      });

      if (isCollection.length > 0) {
        type = 'collection';
      }

      var isReplenishment = _.filter(results, function (result) {
        return result.instance == 'alter_replenishment' && result.type == 'REPL';
      });

      if (isReplenishment.length > 0) {
        type = 'replenishment';
      }

      return type;
    }
  };

  // Document listeners:
  $(document).on('offerToCart.success', function (event, result) {
    $('.js-cart-block-container').removeClass('hidden');
    behavior.addOffer(result);
  });

  // Document listeners:
  $(document).on('addToCart.success', function (event, result) {
    // @setup new brand - this is temporary open/close for testing add to cart
    // a more integrated method with all gnav should be done per brand
    $('.js-cart-block-container').removeClass('hidden');
    behavior.addItem(result);
  });

  $(document).on('loadCart.success', function (event, force) {
    behavior.load(true);
  });

  // Override preventDefault on gnav overlay logic if cart is empty:
  $(document).on('click', '.js-gnav-util-trigger--cart', function (event) {
    event.preventDefault();
    if (state === 'empty') {
      // window.location = $(this).attr('href');
      $('body').removeClass('mobile-gnav-active gnav-active');
      $('.menu__primary--active').removeClass('menu__primary--active');
      $('.js-gnav-util__content').not($(this).next('.js-gnav-util__content')).addClass('hidden');
      $(this).next('.js-gnav-util__content').toggleClass('hidden');

      return true;
    }
  });

  $(document).on('mouseenter', '.js-cart-block-container', _clearCloseTimeout);
  $(document).on('mouseleave', '.js-cart-block-container', _setCloseTimeout);

  $(document).on('click', '.cart-block__items__view-bag', function (event) {
    event.preventDefault();

    _clearCloseTimeout();
    behavior.setState().render();
  });

  $(document).on('click', '.js-gnav-util-trigger--cart', function (event, args) {
    event.preventDefault();
    var $body = $('body');

    if ($('.gnav-util--cart').length) {
      // Do nothing if the cart is empty:
      // if (state === 'empty' || state === 'added') {
      if (data.item_count == 0) {
        return;
      }

      if ($body.hasClass) {
        $('body').removeClass('mobile-gnav-active');
      }
      $('.menu__primary--active').removeClass('menu__primary--active');
      $('.js-gnav-util__content').not($(this).next('.js-gnav-util__content')).addClass('hidden');
      $(this).next('.js-gnav-util__content').toggleClass('hidden');
      behavior.load(true);
    }
  });

  return behavior;
})(
  (window.jQuery = window.jQuery || function () {}),
  (window._ = window._ || {}),
  (window.site = window.site || {}),
  (window.generic = window.generic || {})
);
